:root {
  --border: #000000;
  --bg: #0a141d;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

details summary::-webkit-details-marker {
  display: none;
  content: "";
}

details summary::marker {
  display: none;
  content: "";
}

summary {
  outline: none;
}

* {
  box-sizing: border-box;
}

.spacer {
  flex: 1;
}

.rotated {
  transform: rotate(180deg);
}

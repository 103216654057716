.ui {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background-color: var(--bg);
  width: 1400px;
  margin: 0 auto;
}

.topbar {
  display: flex;
}

.game {
  flex: 1;
  padding: 1em;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  overflow-x: hidden;
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column12 .cardWrapper.display {
  height: 35px;
}

.column18 .cardWrapper.display {
  height: 33px;
}

.column24 .cardWrapper.display {
  height: 31px;
}

.column30 .cardWrapper.display {
  height: 29px;
}

.holder {
  width: 120px;
  border: 3px solid #ffac4e;
  border-radius: 5px;
  cursor: grab;
  position: relative;
}

.holder:after {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  content: "";
  border: 1px solid #ffac4e;
}

.holderInner {
  height: 0;
  padding-top: 150%;
}

.holderWrapper {
  height: 0;
  position: relative;
}

.cardWrapper {
  height: 20px;
}

.cardWrapper.display {
  height: 50px;
}

.horizenWrapper {
  width: 30px;
}

.cardStack {
  display: flex;
}

.control {
  display: flex;
  padding: 1em;
}

.logo {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  font-family: "Oleo Script", cursive;
  color: #ffac4e;
  padding: 1em;
  font-size: 1.5em;
}

.btn {
  transition: background-color 200ms;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  padding: 1em;
  font-size: 1.5em;
  cursor: pointer;
  outline: none;
  position: relative;
}

.chooseList {
  font-size: 1.25em;
  font-weight: bold;
  position: absolute;
  z-index: 100;
  border: 2px solid #555555;
  border-radius: 5px;
  background-color: #2e2e2e;
  padding: 0.5em 0;
  color: #ffffff;
}

.chooseList div {
  padding: 0.5em 1em;
  user-select: none;
  cursor: pointer;
}

.chooseList div:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.details[open] summary {
  background-color: rgba(255, 255, 255, 0.1);
}

.score {
  display: flex;
  align-items: center;
  font-size: 1.5em;
  color: #ffffff;
  padding: 1em;
  font-weight: bold;
  font-family: "Oleo Script", cursive;
}


@media screen and (max-height: 950px) {
  .holder {
    width: 92px;
  }
}
